import Slots1 from "../../assets/images/slots/aviator.png";
import Slots2 from "../../assets/images/slots/twinspin.png";
import Slots3 from "../../assets/images/slots/gonzo.png";
import Slots4 from "../../assets/images/slots/stones.png";
import Slots5 from "../../assets/images/slots/secrets.png";
import Slots6 from "../../assets/images/slots/wheel.png";
import Slots7 from "../../assets/images/slots/777_Strike.png";
import Slots8 from "../../assets/images/slots/10001Nights.png";
import Slots9 from "../../assets/images/slots/Cash_Ultimate.png";
import Slots10 from "../../assets/images/slots/Cash_Volt.png";
import Slots11 from "../../assets/images/slots/Dazzle_Me_Megaways.png";
import Slots12 from "../../assets/images/slots/Divine_Fortune_Megaways.png";
import Slots13 from "../../assets/images/slots/Dynamite_Riches.png";
import Slots14 from "../../assets/images/slots/FortuneHouse.png";
import Slots15 from "../../assets/images/slots/Fruit_Shop_Megaways.png";
import Slots16 from "../../assets/images/slots/Golden_Cryptex.png";
import Slots17 from "../../assets/images/slots/Golden_Tsar.png";
import Slots18 from "../../assets/images/slots/Gorilla_Kingdom.png";
import Slots19 from "../../assets/images/slots/Mystery_Reels_Megaways.png";
import Slots20 from "../../assets/images/slots/Rage_Of_The_Seas.png";
import Slots21 from "../../assets/images/slots/Red-Tiger.png";
import Slots22 from "../../assets/images/slots/Reel_King_Mega.png";
import Slots23 from "../../assets/images/slots/Trollpot_5000.png";
import Slots24 from "../../assets/images/slots/Turn_Your_Fortune.png";
import Slots25 from "../../assets/images/slots/Twin_Spin_Megaways.png";
import Slots26 from "../../assets/images/slots/Vikings_Video_Slot.png";
import Slots27 from "../../assets/images/slots/Willys_Hot_Chillies.png";
import teenpatti2020 from "../../assets/images/teenpatti2020.png";
import LiveCasino1 from "../../assets/images/roulette/Speed-Roulette1.png";
import LiveCasino2 from "../../assets/images/roulette/Diamond-roulette1.png";
import LiveCasino3 from "../../assets/images/roulette/Namaste-roulette1.png";
import LiveCasino5 from "../../assets/images/roulette/Auto-roulette.png";
import LiveCasino6 from "../../assets/images/roulette/Speed-auto-roulette.png";
import LiveCasino7 from "../../assets/images/roulette/Oracle-360-roulette.png";
import LiveCasino9 from "../../assets/images/roulette/Italian-roulette1.png";
import LiveCasino10 from "../../assets/images/roulette/Spanish-roulette.png";

import Casino1 from "../../assets/images/casino/Aviator.png";
import Casino2 from "../../assets/images/casino/32-cards.png";
import Casino3 from "../../assets/images/casino/Baccart.png";
import Casino4 from "../../assets/images/casino/Blackjack.png";
import Casino5 from "../../assets/images/casino/Lucky-7.png";
import Casino6 from "../../assets/images/casino/Megaball.png";
import Casino7 from "../../assets/images/casino/sicbo.png";

import football from "../../assets/images/sports/Soccer.png";
import cricket1 from "../../assets/images/sports/cricket.png";
import inplay from "../../assets/images/sports/In-play.png";
import Cricket from "../../assets/images/sports/Cricket-2.png";
import soccer from "../../assets/images/sports/Tennis.png";
import Tennis from "../../assets/images/sports/badminton-2.png";
import Teenpatti from "../../assets/images/popular/teenpatti.png";
import aviator from "../../assets/images/popular/aviator1.png";
import Andarbahar from "../../assets/images/popular/andarbahar1.png";
import Cards from "../../assets/images/popular/32-cards.png";
import Crazytime from "../../assets/images/popular/Crazy-time.png";
import DoubleBall from "../../assets/images/popular/doubleballroulette.png";
import DragonTiger from "../../assets/images/popular/Dragontiger.png";
import italianroulette from "../../assets/images/popular/Italian-roulette.png";
import Monopoly from "../../assets/images/popular/Monopolylive.jpg";
import Lucky7 from "../../assets/images/popular/lucky7.png";
export const GamesData = [
  {
    href: "/casino/ezugi/TeenPatti02",
    img: <img src={teenpatti2020} alt="Live casino game" />,
    gameName: "Teen Patti",
  },
  {
    href: "/casino/ezugi/SpeedRoulette",
    img: <img src={LiveCasino1} alt="Live casino game" />,
    gameName: "Speed Roulette",
  },
  {
    href: "/casino/ezugi/DiamondRoulette",
    img: <img src={LiveCasino2} alt="Live casino game" />,
    gameName: "Diamond Roulette",
  },
  {
    href: "/casino/ezugi/NamasteRoulette",
    img: <img src={LiveCasino3} alt="Live casino game" />,
    gameName: "Namaste Roulette",
  },
  {
    href: "/casino/ezugi/AutoRoulette",
    img: <img src={LiveCasino5} alt="Live casino game" />,
    gameName: "Auto Roulette",
  },
  {
    href: "/casino/ezugi/SpeedAutoRoulette",
    img: <img src={LiveCasino6} alt="Live casino game" />,
    gameName: "Speed Auto Roulette ",
  },
  {
    href: "/casino/ezugi/OracleRoulette2",
    img: <img src={LiveCasino7} alt="Live casino game" />,
    gameName: "Oracle Roulette 2   ",
  },
  {
    href: "/casino/ezugi/ItalianRoulette",
    img: <img src={LiveCasino9} alt="Live casino game" />,
    gameName: "  Italian Roulette  ",
  },
  {
    href: "/casino/ezugi/SpanishRoulette",
    img: <img src={LiveCasino10} alt="Live casino game" />,
    gameName: "  Spanish Roulette  ",
  },
  {
    href: "/casino/spribe/aviator",
    img: <img src={Casino1} alt="Live casino game" />,
    gameName: "  Aviator  ",
  },
  {
    href: "/casino/ezugi/thirtytwoCards",
    img: <img src={Casino2} alt="Live casino game" />,
    gameName: "  Thirty Two Cards  ",
  },
  {
    href: "/casino/ezugi/Baccarat",
    img: <img src={Casino3} alt="Live casino game" />,
    gameName: "  Baccarat  ",
  },
  {
    href: "/casino/ezugi/Blackjack",
    img: <img src={Casino4} alt="Live casino game" />,
    gameName: "  Blackjack  ",
  },
  {
    href: "/casino/ezugi/lucky7",
    img: <img src={Casino5} alt="Live casino game" />,
    gameName: "  lucky7  ",
  },
  {
    href: "/casino/evolution/Megaball",
    img: <img src={Casino6} alt="Live casino game" />,
    gameName: "  Megaball  ",
  },
  {
    href: "/casino/ezugi/sicbodice01",
    img: <img src={Casino7} alt="Live casino game" />,
    gameName: "  Sicbo dice ",
  },
  {
    href: "/casino/ezugi/twinspin",
    img: <img src={Slots2} alt="Live casino game" />,
    gameName: "  Twinspin ",
  },
  {
    href: "/casino/ezugi/gonzoquest",
    img: <img src={Slots3} alt="Live casino game" />,
    gameName: "  Gonzoquest ",
  },
  {
    href: "/casino/ezugi/asgardianstones",
    img: <img src={Slots4} alt="Live casino game" />,
    gameName: "  Asgardian stones ",
  },
  {
    href: "/casino/ezugi/secrets",
    img: <img src={Slots5} alt="Live casino game" />,
    gameName: " Secrets ",
  },
  {
    href: "/casino/ezugi/grandwheel",
    img: <img src={Slots6} alt="Live casino game" />,
    gameName: "Grandwheel ",
  },
  {
    href: "/casino/ezugi/Strike777",
    img: <img src={Slots7} alt="Live casino game" />,
    gameName: "Strike 777 ",
  },
  {
    href: "/casino/ezugi/Nights1000",
    img: <img src={Slots8} alt="Live casino game" />,
    gameName: "Nights 1000 ",
  },
  {
    href: "/casino/ezugi/CashUltimate",
    img: <img src={Slots9} alt="Live casino game" />,
    gameName: " Cash Ultimate ",
  },
  {
    href: "/casino/ezugi/CashVolt",
    img: <img src={Slots10} alt="Live casino game" />,
    gameName: " Cash Volt ",
  },
  {
    href: "/casino/ezugi/DazzleMegaways",
    img: <img src={Slots11} alt="Live casino game" />,
    gameName: " Dazzle Megaways ",
  },
  {
    href: "/casino/ezugi/DivinieFortuneMegaways",
    img: <img src={Slots12} alt="Live casino game" />,
    gameName: " Divinie Fortune Megaways ",
  },
  {
    href: "/casino/ezugi/Dynamiteriches",
    img: <img src={Slots13} alt="Live casino game" />,
    gameName: " Dynamite riches ",
  },
  {
    href: "/casino/ezugi/FortuneHouse",
    img: <img src={Slots14} alt="Live casino game" />,
    gameName: "Fortune house ",
  },
  {
    href: "/casino/ezugi/FruitShopMegaways",
    img: <img src={Slots15} alt="Live casino game" />,
    gameName: "Fruit shop megaways ",
  },
  {
    href: "/casino/ezugi/GoldenCryptex",
    img: <img src={Slots16} alt="Live casino game" />,
    gameName: "Golden cryptex ",
  },
  {
    href: "/casino/ezugi/GoldenTsar",
    img: <img src={Slots17} alt="Live casino game" />,
    gameName: "Golden tsar ",
  },
  {
    href: "/casino/ezugi/GorillaKingdom",
    img: <img src={Slots18} alt="Live casino game" />,
    gameName: "Gorilla kingdom ",
  },
  {
    href: "/casino/ezugi/MystryReels",
    img: <img src={Slots19} alt="Live casino game" />,
    gameName: "Mystery Reels ",
  },
  {
    href: "/casino/ezugi/RageoftheSea",
    img: <img src={Slots20} alt="Live casino game" />,
    gameName: "Rage of the Sea ",
  },
  {
    href: "/casino/ezugi/ReelKingMegaways",
    img: <img src={Slots22} alt="Live casino game" />,
    gameName: "Reel king megaways ",
  },
  {
    href: "/casino/ezugi/TurnFortune",
    img: <img src={Slots24} alt="Live casino game" />,
    gameName: "Turn fortune ",
  },
  {
    href: "/casino/ezugi/TwinSpinMegaways",
    img: <img src={Slots25} alt="Live casino game" />,
    gameName: "Twin spin megaways ",
  },
  {
    href: "/casino/ezugi/VikingsSlots",
    img: <img src={Slots26} alt="Live casino game" />,
    gameName: "Vikings slots ",
  },
  {
    href: "/casino/ezugi/WillysChills",
    img: <img src={Slots27} alt="Live casino game" />,
    gameName: "Willys chills ",
  },
  {
    href: "/exchange/Cricket",
    img: <img src={cricket1} alt="Sports game" />,
    gameName: "Cricket ",
  },
  {
    href: "/exchange/Soccer",
    img: <img src={football} alt="Sports game" />,
    gameName: "Soccer ",
  },
  {
    href: "/casino/ezugi/AndarBaharLive",
    img: <img src={Andarbahar} className="popular-img" />,
    gameName: "Andar bahar live ",
  },
  {
    href: "/casino/ezugi/thirtytwoCards",
    img: <img src={Cards} className="popular-img" />,
    gameName: " Thirtytwo Cards ",
  },
  {
    href: "/casino/evolution/crazytime",
    img: <img src={Crazytime} className="popular-img" />,
    gameName: "  Crazytime ",
  },
  {
    href: "/casino/evolution/DoubleBallRoulette",
    img: <img src={DoubleBall} className="popular-img" />,
    gameName: "  Double ball roulette ",
  },
  {
    href: "/casino/evolution/DragonTiger1",
    img: <img src={DragonTiger} className="popular-img" />,
    gameName: "Dragon Tiger ",
  },
  {
    href: "/casino/ezugi/ItalianRoulette",
    img: <img src={italianroulette} className="popular-img" />,
    gameName: "Italian roulette ",
  },
  {
    href: "/casino/ezugi/sevenLucky",
    img: <img src={Lucky7} className="popular-img" />,
    gameName: "Lucky7 ",
  },
];
