import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

import Play from "../../../../assets/images/playbtn.png";

const Spribe = () => {
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>

      <main className="main">
        <div className="slots-section spribesection mt-5">
          <div className="games-section">
            <div className="game-heading d-flex justify-content-between">
              <span>Spribe</span>
              <div className="back-link"> Back </div>
            </div>
            <ul>
              <li className="game-section">
                <a href="/casino/spribe/mines">
                  <img src="./images/spribe/casino2.jpg" />
                  <div className="casino_overlay">
                    <img src={Play} alt="mines" />
                  </div>
                  <p>Mines</p>
                </a>
              </li>
              <li className="game-section">
                <a href="/casino/spribe/goal">
                  <img src="./images/spribe/casino3.png" />
                  <div className="casino_overlay">
                    <img src={Play} alt="goal" />
                  </div>
                  <p>Goal</p>
                </a>
              </li>
              <li className="game-section">
                <a href="/casino/spribe/dice">
                  <img src="./images/spribe/casino7.jpg" alt="dice" />
                  <div className="casino_overlay">
                    <img src={Play} />
                  </div>
                  <p>Dice</p>
                </a>
              </li>
              <li className="game-section">
                <a href="/casino/spribe/aviator">
                  <img src="./images/spribe/casino1.png" />
                  <div className="casino_overlay">
                    <img src={Play} alt="Play" />
                  </div>
                  <p>Aviator</p>
                </a>
              </li>
              <li className="game-section">
                <a href="/casino/spribe/plinko">
                  <img src="./images/spribe/casino4.jpg" />
                  <div className="casino_overlay">
                    <img src={Play} alt="play" />
                  </div>
                  <p>Plinko</p>
                </a>
              </li>
              <li className="game-section">
                <a href="/casino/spribe/miniroulette">
                  <img src="./images/spribe/casino5.png" />
                  <div className="casino_overlay">
                    <img src={Play} alt="play" />
                  </div>
                  <p>Mini Roulette</p>
                </a>
              </li>
              <li className="game-section">
                <a href="/casino/spribe/hilo">
                  <img src="./images/spribe/casino6.png" />
                  <div className="casino_overlay">
                    <img src={Play} alt="play" />
                  </div>
                  <p>Hilo</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </main>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Spribe;
