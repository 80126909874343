import React from "react";
import { useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import { GamesData } from "./GamesData";
const GamesSearchModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [filteredData, setFilteredData] = useState(null);
  const [searchText, setSearchText] = useState(false);

  const handleSearch = (e) => {
    if (e) {
      setSearchText(true);

      let filteredValue = GamesData?.filter((item) => {
        return item.gameName.indexOf(e) > -1;
      });
      setFilteredData(filteredValue);
    } else {
      setSearchText(false);
    }
  };
  console.log("FilterData", filteredData);
  return (
    <div>
      <div className="searchGames" onClick={handleShow}>
        <p>Search Games</p>
        <BiSearch />
      </div>

      <div>
        <Modal show={show} onHide={handleClose} className="searchGames">
          <Modal.Header closeButton>
            <Modal.Title>Search For Games</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <InputGroup className="mb-3 search-icon">
              <Form.Control
                placeholder="Search"
                aria-label="Text input with dropdown button"
                type="search"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </InputGroup>
            <div className="GamesShow">
              {filteredData &&
                filteredData.map((item) => (
                  <div className="displayGames">
                    <a href={item.href}> {item.img}</a>
                  </div>
                ))}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default GamesSearchModal;
